.style1{
    font-family: sans-serif;
    text-align: center;
    width: 90vw;
    margin: 5rem auto;
    display:  grid;
    gap: 2rem;
}

h1 {
    text-align: center;
    margin-top: 5rem;
}

.productImgMaxSize{
    max-width: 250px;
    max-height: 250px;
}

.productListDisplay{
    columns: 3;
}